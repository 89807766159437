export class Documento {
    constructor(numero, nombre, abreviatura, id_empresa) {
        this.numero = numero;
        this.nombre = nombre;
        this.abreviatura = abreviatura;
        this.id_empresa = id_empresa;
    }
    toString() {
        return this.numero + ' ' + this.nombre;
    }
}

// Firestore data converter
const documentoConverter = {
    toFirestore: (documento) => {
        return {
            numero: documento.numero,
            nombre: documento.nombre,
            id_empresa: documento.id_empresa,
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Documento(data.numero, data.nombre, data.id_empresa);
    }
};