import React, { useEffect, useState } from "react";
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';

/** MUI */
import {
    AppBar,
    Box, Button,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead, TableRow,
    Typography
} from '@mui/material';

/** ICONOS */
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import EditIcon from '@mui/icons-material/Edit';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import MaterialReactTable from "material-react-table";
import FireBaseApp from "../firebase/firebase";

/** Estilos CSS MUI */
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ItemResso = (props) => {
    const { children, activeTree, selectedTree, id_subcategoria } = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aceptados, setAceptados] = useState([]);
    const [open, setOpen] = useState(false);
    const [categoria, setCategoria] = useState('');
    const [subCategoria, setSubCategoria] = useState('');
    const [resso, setResso] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        retreiveItems();
        getCategoria(id_subcategoria);
    }, []);

    const closeModal = () => setOpen(false);

    const columns = [
        {
            accessorKey: 'numero', //access nested data with dot notation
            header: 'Número',
            size: 20
        },
        {
            accessorKey: 'nombre', //access nested data with dot notation
            header: 'Nombre',
            size: 400
        },
        {
            accessorKey: 'ponderacion', //access nested data with dot notation
            header: 'Ponderación',
            size: 20
        },
        {
            accessorKey: 'resultado', //access nested data with dot notation
            header: 'Resultado',
            size: 20
        },
        {
            accessorKey: 'porcentaje', //access nested data with dot notation
            header: '%',
            size: 20
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Verificar',
            size: 50,
            Cell: ({ cell, row }) => {
                if (cell.getValue() !== "") {
                    return (
                        <Button variant="contained" color="primary" onClick={()=>verItem(row.original.numero,cell.getValue())}>VER</Button>
                    )
                    /* return (
                        <Button variant="contained" color="primary" onClick={() => abrirProgramaitem2(cell.getValue())}>Ver</Button>
                    )
                } else {
                    return (
                        <Button variant="contained" color="primary" onClick={verAceptados}>Ver</Button>
                    ) */
                }
            }
        },
        /* {
            accessorKey: 'numero', //access nested data with dot notation
            header: 'Edición',
            size: 50,
            Cell: ({ cell, row }) => {
                return (
                    <Button variant="outlined" color="secondary" onClick={() => abrirItem(cell.getValue(), row.original.id)}>Editar</Button>
                )
            }
        }, */
    ];

    const abrirItem = (numero, id_item) => {
        navigate("/docsresso/" + resso.id_contrato + "/" + id_item+"/"+subCategoria.id);
        /* if (numero === 3) {
            navigate("/itempol/" + resso.id_contrato + "/" + id_item);
        } */
        /* else if (numero === 28 || numero == 13 || numero == 14 || numero == 16 || numero == 18 || numero == 31 || numero == 36 || numero == 38 || numero == 45) {
            navigate("/itemprocedimiento/" + resso.id_contrato + "/" + id_item);
        } */
        /* else {
            navigate("/docsresso/" + resso.id_contrato + "/" + id_item+"/"+subCategoria.id);
        } */
    }

    const abrirProgramaitem2 = (url) => {
        window.open(url, "_blank");
    };

    const verItem = (numero, id_item) => {
        /* if(numero == 28) {
            navigate("/veritemproc/"+id_item+"/"+resso.id_contrato+"/"+id_subcategoria);
        } */
            navigate("/veritem/" + resso.id_contrato + "/" + id_item+"/"+subCategoria.id);
    }

    const verAceptados = async () => {
        console.log("Ver aceo");
        setLoading(true);
        var accept = [];
        var temp_ace = [];
        const acepRef = await collection(FireBaseApp.db, "persona_items");
        const snapAcep = await getDocs(acepRef);
        snapAcep.forEach((acDoc) => {
            console.log(acDoc.id);
            accept.push({ ...acDoc.data() });
        });
        const perRef = await collection(FireBaseApp.db, "personas");
        const snapPers = await getDocs(perRef);
        snapPers.forEach((perDoc) => {
            if (accept.some(v => v.rut_persona == perDoc.data().rut)) {
                console.log("Acep", perDoc.data().rut);
                var temp_data = {
                    nombre: perDoc.data().nombres + " " + perDoc.data().ap_paterno,
                    rut: perDoc.data().rut,
                    aceptado: true
                };
                temp_ace.push(temp_data);
            } else {
                var temp_data = {
                    nombre: perDoc.data().nombres + " " + perDoc.data().ap_paterno,
                    rut: perDoc.data().rut,
                    aceptado: false
                };
                temp_ace.push(temp_data);
            }
            console.log("Arr Ace", temp_ace);
            setAceptados([...temp_ace]);
        });
        setLoading(false);
        setOpen(true);
    };


    async function retreiveItems() {
        var item_temp = [];
        const queryItems = await query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", id_subcategoria));
        const snapItem = await getDocs(queryItems);
        snapItem.forEach((doc) => {
            var item_data = { id: doc.id, ...doc.data() };
            item_temp.push(item_data);
        });
        item_temp.sort(function(a,b) {
            return a.numero - b.numero
        });
        setItems([...item_temp]);
    };

    async function getCategoria(id_subcategoria) {
        const scatRef = await doc(FireBaseApp.db, "subcategoria", id_subcategoria);
        const scatSnap = await getDoc(scatRef);
        setSubCategoria({id: scatSnap.id, ...scatSnap.data()});

        if (scatSnap.exists()) {
            const catRef = await doc(FireBaseApp.db, "categoria", scatSnap.data().id_categoria);
            const catSnap = await getDoc(catRef);
            setCategoria(catSnap.data());
            //Get Resso
            const ressRef = await doc(FireBaseApp.db, "resso", catSnap.data().id_resso);
            const ressSnap = await getDoc(ressRef);
            if (ressSnap.exists()) {
                setResso(ressSnap.data());
            }
        }
    }

    return (
        <div hidden={activeTree !== selectedTree}>
            {activeTree === selectedTree &&
                <Box mx={2}>
                    {children}
                    {/* {JSON.stringify(items, null, null, 2)} */}
                    <Box sx={{ margin: 3 }}>
                        <Typography variant="h5" sx={{
                            color: 'green',
                            fontWeight: 'bold'
                        }}>{categoria.nombre}</Typography>
                        <Typography variant="h6">{subCategoria ? subCategoria.nombre : ""}</Typography>
                    </Box>
                    <MaterialReactTable
                        columns={columns}
                        data={items}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <IconButton
                                    color="secondary"
                                    onClick={() => {
                                        //table.setEditingRow(row);
                                        abrirItem(row.original.numero, row.original.id);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                {/* <IconButton
                                    color="error"
                                    onClick={() => {
                                        requerimientos.splice(row.index, 1); //assuming simple data table
                                        setRequerimientos([...requerimientos]);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton> */}
                            </Box>
                        )}
                    />
                </Box>}
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ margin: 3 }}>
                    <AppBar>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                            Aceptaciones
                        </Typography>
                    </AppBar>
                    {/* <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={(e)=>onChangeCarta(e)} />} label="Carta de aviso?" />
                    </FormGroup> */}
                    <Box sx={{
                        margin: 3
                    }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Rut
                                    </TableCell>
                                    <TableCell>
                                        Nombre
                                    </TableCell>
                                    <TableCell>
                                        Aceptado
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {aceptados.map(ac => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {ac.rut}
                                            </TableCell>
                                            <TableCell>
                                                {ac.nombre}
                                            </TableCell>
                                            <TableCell>
                                                {ac.aceptado == true ? <CheckBoxTwoToneIcon color="success" /> : <CancelTwoToneIcon color="error" />}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <Divider variant='middle' sx={{ marginTop: 3 }}></Divider>
                    <Box sx={{ alignItems: 'end', marginTop: 3, display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" onClick={closeModal}>Cerrar</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default ItemResso;