import { ConfirmProvider } from "material-ui-confirm";
import { Route, Routes } from 'react-router-dom';
import './App.css';

/** Paginas */
import Arbol from './pages/arbol.page';
import Categorias from './pages/categorias.page';
import DocumentoPersona from './pages/documentospersona.page';
import Personal from './pages/persona.page';
import Procedimientos from './pages/procedimientos.page';
import ProcedimientosAsociados from './pages/procedimientos_asociados.page';
import Resultado from './pages/resultado.page';
import Welcome from './pages/welcome.page';

/** Importar ITEMS */
import DocsResso from './pages/items/docsresso.page';
import Item3 from './pages/items/item3.page';
import ItemPoliticas from './pages/items/itempolitica.page';
import ItemProcedimiento from './pages/items/itemprocedimiento.page';
import VerItemProcedimiento from './pages/items/veritemproce.page';

import AddPersona from './pages/addpersona.page';
import Brechas from './pages/brechas.page';
import IngresoDocumentos from './pages/ingresodocumentos.page';
import VerItem from "./pages/items/veritem.page";
import Login from './pages/login.page';
import MenuReporteRRHH from "./pages/menureporterrhh.page";
import MenuReporteGerencial from "./pages/menureportger.page";
import Preguntas from './pages/preguntas.page';
import GerenciaPBI from './reportes/gerencia.reporte';

function App() {
  return (
    <ConfirmProvider>
    <Routes>
      <Route path='/reportepersonal/:id_report' element={<GerenciaPBI />} />
      <Route path='/reportegerencial' element={<MenuReporteGerencial />} />
      <Route path='/reporterrhh' element={<MenuReporteRRHH />} />
      <Route path='/maestrodocumentos/:id_contrato' element={<IngresoDocumentos />} />
      <Route path='/brechas/:id_contrato' element={<Brechas />} />
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<Login />} />
      <Route path='/categorias' element={<Categorias />} />
      <Route path='/resultado' element={<Resultado />} />
      <Route path='/welcome' element={<Welcome />} />
      <Route path='/inicio/:id_contrato/:seltree' element={<Arbol />} />
      <Route path='/personal/:id_contrato' element={<Personal />} />
      <Route path='/agregarpersonal/:id_contrato/:id_persona' element={<AddPersona />} />
      <Route path='/documento/:rut_persona/:id_contrato' element={<DocumentoPersona />} />
      <Route path='/item3/:id_contrato' element={<Item3 />} />
      <Route path='/itempol/:id_contrato/:id_item' element={<ItemPoliticas />} />
      <Route path='/itemprocedimiento/:id_contrato/:id_item' element={<ItemProcedimiento />} />
      <Route path='/preguntas/:id_contrato/:id_procedimiento' element={<Preguntas />} />
      <Route path='/procedimientos/:id_contrato' element={<Procedimientos />} />
      <Route path='/procedimientos_asociados/:id_contrato/:id_procedimiento' element={<ProcedimientosAsociados />} />
      <Route path='/docsresso/:id_contrato/:id_item/:id_subcategoria' element={<DocsResso />} />
      <Route path='/veritem/:id_contrato/:id_item/:id_subcategoria' element={<VerItem />} />
      <Route path='/veritemproc/:id_item/:id_contrato/:id_subcategoria' element={<VerItemProcedimiento />} />
    </Routes>
    </ConfirmProvider>
  );
}

export default App;
