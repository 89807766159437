import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

/** MUI */
import { Box, Button, Container, Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";

/** FireBase */
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

import ResponsiveAppBar from "../components/navbar.component";

const ProcedimientosAsociados = () => {
    const [personas, setPersonas] = useState([]);
    const [personasTotal, setPersonasTotal] = useState([]);
    const { id_contrato, id_procedimiento } = useParams();

    useEffect(() => {
        retreivePersonasAsociadas(id_procedimiento);
        retreivePersonasTotal(id_contrato);
    }, []);

    async function retreivePersonasAsociadas(id_proc) {
        var per_temp = [];
        const queryPer = await query(collection(FireBaseApp.db, "procedimiento_persona"), where('id_procedimiento', '==', id_proc));
        const snapPer = await getDocs(queryPer);
        if (!snapPer.empty) {
            snapPer.forEach(async (pDoc) => {
                //obtenemos el documento de cada persona
                const perRef = await doc(collection(FireBaseApp.db, "personas"), pDoc.data().id_persona);
                const persona = await getDoc(perRef);
                const data_persona = { id: persona.id, ...persona.data() };
                per_temp.push(data_persona);
                setPersonas([...per_temp]);
            });
        }
    };

    async function retreivePersonasTotal(id_cont) {
        var per_temp = [];
        const queryPers = await query(collection(FireBaseApp.db,"personas"),where("id_contrato","==",id_cont));
        const snapPers = await getDocs(queryPers);
        if(!snapPers.empty) {
            snapPers.forEach((pDoc)=>{
                const data_persona = {id: pDoc.id, ...pDoc.data()};
                per_temp.push(data_persona);
                setPersonasTotal([...per_temp]);
            });
        };
    };

    const columns = [
        {
            accessorKey: 'rut',
            header: 'Rut',
            size: 40
        },
        {
            accessorKey: 'nombre',
            header: 'Nombres',
            size: 100
        },
        {
            accessorKey: 'apellido_paterno',
            header: 'Apellido Paterno',
            size: 100
        },
        {
            accessorKey: 'cargo',
            header: 'Cargo',
            size: 100
        },
    ];

    const columns_total = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 40
        },
        {
            accessorKey: 'rut',
            header: 'Rut',
            size: 40
        },
        {
            accessorKey: 'nombre',
            header: 'Nombres',
            size: 100
        },
        {
            accessorKey: 'apellido_paterno',
            header: 'Apellido Paterno',
            size: 100
        },
        {
            accessorKey: 'cargo',
            header: 'Cargo',
            size: 100
        },
    ];

    async function existePersona(id_persona) {
        const queryPP = await query(collection(FireBaseApp.db,"procedimiento_persona"),where("id_persona","==",id_persona),where("id_procedimiento","==",id_procedimiento));
        const snapPP = await getDocs(queryPP);
        console.log(snapPP.empty);
        if(snapPP.empty) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <div>
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Typography color="green" variant="h6" sx={{
                    textAlign: "center",
                    alignItems: "center",
                    margin: 3
                }}>
                    Lista de Personal Asociado
                </Typography>
                <Box sx={{
                    margin: 3,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <MaterialReactTable
                        data={personas}
                        columns={columns}
                    />
                </Box>
                <Typography color="green" variant="h6" sx={{
                    textAlign: "center",
                    alignItems: "center",
                    margin: 3
                }}>
                    Lista de Personal
                </Typography>
                <Box sx={{
                    margin: 3,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <MaterialReactTable
                        data={personasTotal}
                        columns={columns_total}
                        enableRowSelection
                        initialState={{ columnVisibility: { id: false } }}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleContact = async () => {
                                var arr_user = [];
                                //Agregamos los usuarios a un arreglo
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    //alert('contact ' + row.getValue('rut') + ' ' + arr_id[0]);
                                    arr_user.push(row.getValue('id'));
                                });

                                //Asociamos el procedimiento a los usuarios
                                for (var i in arr_user) {
                                    const existe = await existePersona(arr_user[i])
                                    if(!existe) {
                                        console.log("Agregando ...");
                                        const procPersRef = await addDoc(collection(FireBaseApp.db,"procedimiento_persona"),{
                                            id_procedimiento: id_procedimiento,
                                            id_persona: arr_user[i]
                                        });
                                    }
                                };
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="info"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleContact}
                                        variant="contained"
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            );
                        }}
                    />
                </Box>
            </Container>
        </div>
    )

}

export default ProcedimientosAsociados;