import React from "react";
import { useNavigate } from "react-router-dom";

/** MUI */
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Stack
} from "@mui/material";

/** Componentes */
import ResponsiveAppBar from "../components/navbar.component";

const MenuReporteGerencial = () => {
    const navigate = useNavigate();

    return (
        <div>
            <ResponsiveAppBar />
            <Container sx={{
                justifyContent: "center"
            }}>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex"
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Card sx={{
                                boxShadow: 2,
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex"
                            }}>
                                <CardContent>
                                    <Stack spacing={2} direction="column">
                                        <img src="img/report_personal.png" alt="Reporte Personal" width="130" height="150" />
                                        <Button variant="contained" color="success" onClick={()=>navigate("/reportepersonal/''")}>PERSONAL</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card sx={{
                                boxShadow: 2,
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex"
                            }}>
                                <CardContent>
                                    <Stack spacing={2} direction="column">
                                        <img src="img/personal_spot.png" alt="Reporte Personal" width="130" height="150" />
                                        <Button variant="contained" color="success" onClick={()=>navigate("/reportepersonal/b1f3f7f18d34c643134c")}>PERSONAL SPOT</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Card sx={{
                                boxShadow: 2,
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                display: "flex"
                            }}>
                                <CardContent>
                                    <Stack spacing={2} direction="column">
                                        <img src="img/resso.png" alt="Reporte Personal" width="130" height="150" />
                                        <Button variant="contained" color="success" onClick={()=>navigate("/reportepersonal/ReportSection23e4a70dea13ac480522")}>RESSO</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default MenuReporteGerencial;