import { useFormik } from 'formik';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { saveAs } from 'save-as';
import * as Yup from 'yup';

/** MUI */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
    AppBar,
    Autocomplete,
    Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';


import MUIDataTable from "mui-datatables";

/** FireBase */
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import FireBaseApp from "../firebase/firebase";

/** Componentes */
import Footer from '../components/footer.component';
import ResponsiveAppBar from '../components/navbar.component';

const getMuiTheme = () => createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '@media (max-width:959.95px)': {
                        whiteSpace: "normal",
                        height: "100%",
                        paddingLeft: "5px",
                        display: "inherit"
                    },
                }
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    paddingTop: "15px",
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }
            }
        }
    }
});

function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

function calculoTiempo(fecha) {
    var f_original = moment(fecha, 'DD-MM-YYYY').toDate();
    //var f_original = new Date(fecha);
    var hoy = new Date();
    var f_60_mas = addDays(new Date(), 60);
    var respuesta = "";

    //console.log("Fecha termino", f_original);
    if (f_original <= hoy) {
        respuesta = "NOOK";
    } else if (f_original >= hoy && f_original >= f_60_mas) {
        respuesta = "OK";
    } else if (f_original >= hoy && f_original <= f_60_mas) {
        respuesta = "60";
    }
    return respuesta;
}

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const DocumentoPersona = () => {
    const { rut_persona, id_contrato } = useParams();
    const [persona, setPersona] = useState('');
    const [documentos, setDocumentos] = useState([]);
    const [openModalDoc, setOpenModalDoc] = useState(false);
    const [document, setDocument] = useState('');
    const [archivo, setArchivo] = useState('');
    const [loadDocumento, setLoadDocumento] = useState(false);
    const storage = getStorage(FireBaseApp.app);
    const [maestroDocumento, setMaestroDocumentos] = useState([]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const confirm = useConfirm();
    const [docAutoC, setDocsAutoC] = useState([]);
    const [usuario, setUsuario] = useState('');
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        retreivePersona(rut_persona);
        var user = JSON.parse(localStorage.getItem("usuario"));
        setUsuario(user);
        retreiveMaestroDocumentos(user.id_empresa);
    }, []);

    const closeModalDoc = () => setOpenModalDoc(false);

    const validationSchema = Yup.object().shape({
        nombre: Yup.string().required('El nombre es requerido'),
        fecha_inicio: Yup.date().required("La Fecha de Inicio es Requerida").nullable(),
        //fecha_termino: Yup.string("La Fecha de Termino es Requerida"),
        //acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
    });

    const formik = useFormik({
        initialValues: {
            id: '',
            nombre: '',
            fecha_inicio: '',
            fecha_termino: '',
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            if (!formik.values.id) {
                if (!archivo) {
                    alert("Debe adjuntar un archivo");
                    return;
                }
                console.log(formik.values);
                setLoadDocumento(true);
                const storageRef = ref(storage, `/documentos_personas/${rut_persona}/${archivo.name}`);
                const uploadTask = uploadBytesResumable(storageRef, archivo);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // update progress
                        console.log("Procentaje", percent);
                        //setPercent(percent);
                    },
                    (err) => {
                        console.error(err)
                        setLoadDocumento(false);
                        formik.resetForm();
                        setArchivo('');
                        enqueueSnackbar("Ocurrio un error al cargar el Documento !!", { variant: 'error' });
                        closeModalDoc();
                    },
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            console.log(url);
                            //Actualizamos el derivado
                            const docRef = await addDoc(collection(FireBaseApp.db, "documentos"), {
                                nombre: formik.values.nombre,
                                fecha_inicio: formik.values.fecha_inicio,
                                fecha_termino: formik.values.fecha_termino,
                                rut: rut_persona,
                                url: url,
                            });
                            retreivePersona(rut_persona);
                            setLoadDocumento(false);
                            formik.resetForm();
                            setArchivo('');
                            closeModalDoc();
                            enqueueSnackbar("Documento Cargado Correctamente !!", { variant: 'success' });
                            //setPercent(0);
                        });
                    }
                );
            } else {
                //Actualizamos el documento
                setLoadDocumento(true);
                const docRef = await doc(FireBaseApp.db, "documentos", formik.values.id);
                const docSnap = await getDoc(docRef);
                if (archivo) {
                    const storageRef = ref(storage, `/documentos_personas/${rut_persona}/${archivo.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, archivo);

                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );

                            // update progress
                            console.log("Procentaje", percent);
                            //setPercent(percent);
                        },
                        (err) => {
                            console.error(err)
                            setLoadDocumento(false);
                            formik.resetForm();
                            setArchivo('');
                            enqueueSnackbar("Ocurrio un error al cargar el Documento !!", { variant: 'error' });
                            closeModalDoc();
                        },
                        () => {
                            // download url
                            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                                console.log(url);
                                //Actualizamos el derivado
                                updateDoc(docRef, {
                                    nombre: formik.values.nombre,
                                    fecha_inicio: formik.values.fecha_inicio,
                                    fecha_termino: formik.values.fecha_termino,
                                    url: url,
                                });
                                retreivePersona(rut_persona);
                                setLoadDocumento(false);
                                formik.resetForm();
                                setArchivo('');
                                closeModalDoc();
                                enqueueSnackbar("Documento Cargado Correctamente !!", { variant: 'success' });
                                //setPercent(0);
                            });
                        }
                    );
                } else {
                    updateDoc(docRef, {
                        nombre: formik.values.nombre,
                        fecha_inicio: formik.values.fecha_inicio,
                        fecha_termino: formik.values.fecha_termino
                    });
                    retreivePersona(rut_persona);
                    setLoadDocumento(false);
                    formik.resetForm();
                    setArchivo('');
                    closeModalDoc();
                    enqueueSnackbar("Documento Actualizado Correctamente !!", { variant: 'success' });
                }
            }
        }
    });

    const columns = [
        { name: 'id', label: 'ID', options: { display: false } },
        { name: 'nombre', label: 'Nombre Documento' },
        { name: 'fecha_inicio', label: 'Fecha Inicio' },
        { name: 'fecha_termino', label: 'Fecha Termino' },
        {
            name: 'fecha_termino', label: 'Estado', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    //console.log(calculoTiempo(value))
                    if (calculoTiempo(value) === "OK") {
                        return (
                            <CheckCircleOutlineIcon color="success" />
                        )
                    } else if (calculoTiempo(value) === "NOOK") {
                        return (
                            <DangerousIcon color="error" />
                        );
                    } else if (calculoTiempo(value) === "60") {
                        return (
                            <ReportProblemIcon color="warning" />
                        )
                    }
                }
            }
        },
        {
            name: 'url', label: 'Descarga', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    //console.log(calculoTiempo(value))
                    if (value !== "") {
                        return (
                            <IconButton onClick={() => abriRuta(value)} color='primary'><PictureAsPdfIcon /></IconButton>
                        )
                    } else {
                        return (
                            <IconButton color='error'><PictureAsPdfIcon /></IconButton>
                        )
                    }
                }
            }
        },
        {
            name: 'id', label: 'Editar', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <EditIcon onClick={() => editarDoc(value)}>Editar</EditIcon>
                    )
                }
            }
        },
        {
            name: 'id', label: 'Eliminar', options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DeleteIcon onClick={() => eliminarDoc(value)}>Eliminar</DeleteIcon>
                    )
                }
            }
        }
    ];

    const eliminarDoc = async (id_doc) => {
        confirm({ description: `Esta seguro de eliminar el documento?`, title: "Eliminar Documento", confirmationText: "SI", cancellationText: "NO" })
            .then(async () => {
                setLoading(true);
                const docRef = doc(FireBaseApp.db, "documentos", id_doc);
                await deleteDoc(docRef);
                retreivePersona(rut_persona);
                enqueueSnackbar("Documento Eliminado Correctamente !!", { variant: 'success' });
                setLoading(false);
            }).catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const abriRuta = (link) => {
        if (link !== null) {
            window.open(link);
        }
    };

    const options = {
        selectableRows: false,
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        filterType: 'multiselect',
        responsive: 'vertical',
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron datos",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por Pagina:",
                displayRows: "of",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "TODOS",
                title: "FILTROS",
                reset: "RESETEAR",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Tabla Columnas",
            },
            selectedRows: {
                text: "Fila(s) seleccionadas",
                delete: "Eliminar",
                deleteAria: "Eliminar Filas Seleccionadas",
            },
        },
    };

    async function retreivePersona(rut_p) {
        console.log(rut_p);
        setLoading(true);
        setDocumentos([]);
        var temp_docs = [];
        const q = query(collection(FireBaseApp.db, "personas"), where("rut", "==", rut_p));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
            console.log(doc.data().nombre.charAt(0));
            setPersona({ id: doc.id, ...doc.data() });
            //Buscamos los documentos
            const d = query(collection(FireBaseApp.db, "documentos"), where("rut", "==", rut_p));
            const queryDSnap = await getDocs(d);
            queryDSnap.forEach((ddoc) => {
                const documento = {
                    id: ddoc.id,
                    nombre: ddoc.data().nombre,
                    fecha_inicio: ddoc.data().fecha_inicio ? moment(ddoc.data().fecha_inicio?.toDate(), "DD-MM-YYYY").format("DD-MM-YYYY").toString() : "",
                    fecha_termino: ddoc.data().fecha_termino ? moment(ddoc.data().fecha_termino?.toDate(), "DD-MM-YYYY").format("DD-MM-YYYY").toString() : "",
                    url: ddoc.data().url
                }
                temp_docs.push(documento);
            });
            setDocumentos([...temp_docs]);
            setLoading(false);
        });
    };

    const handleDocument = (valor, nombre) => {
        console.log(valor);
        setDocument({ ...document, [nombre]: valor });
    };

    const agregarDoc = data => {
        console.log(data);
        setDocument(null);
    };

    const editarDoc = async (valor) => {
        console.log(valor);
        //Obtenemos el document documento
        const docuRef = await doc(FireBaseApp.db, "documentos", valor);
        const docuSnap = await getDoc(docuRef);
        console.log(docuSnap.data());
        formik.setFieldValue('id', docuSnap.id);
        formik.setFieldValue('nombre', docuSnap.data().nombre);
        formik.setFieldValue('fecha_inicio', docuSnap.data().fecha_inicio ? docuSnap.data().fecha_inicio.toDate() : new Date());
        formik.setFieldValue('fecha_termino', docuSnap.data().fecha_termino ? docuSnap.data().fecha_termino.toDate() : new Date());
        //setDocument({ id: docuSnap.id, ...docuSnap.data() });
        setOpenModalDoc(true);
    };

    const actualizarDoc = () => {
        console.log(document);
    };

    const openNewDocument = () => {
        formik.resetForm();
        setDocument('');
        setOpenModalDoc(true);
    };

    async function retreiveMaestroDocumentos(id_empresa) {
        var temp = [];
        var temp_auto = [];
        console.log("ID EMPRESA", id_empresa);
        const queryMD = await query(collection(FireBaseApp.db, "maestro_documentos"), where("id_empresa", "==", id_empresa));
        const dataMD = await getDocs(queryMD);
        if (!dataMD.empty) {
            dataMD.forEach((docMD) => {
                temp.push({ id: docMD.id, ...docMD.data() });
                var data_auto = { label: docMD.data().nombre };
                temp_auto.push(data_auto);
            });
            temp.sort(function (a, b) {
                if (parseInt(a.numero) > parseInt(b.numero)) {
                    return 1;
                } else {
                    return -1;
                }
            });
            setMaestroDocumentos([...temp]);
            setDocsAutoC([...temp_auto]);
        }
    };

    function urlToPromise(url) {
        setLoading(true);
        console.log("URL", url);
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                    setLoading(false);
                } else {
                    resolve(data);
                    setLoading(false);
                }
            });
        });
    }

    const descargarArchivos = () => {
        setLoading(true);
        var rut_ = persona.rut.replaceAll(".", "");//"Punto " + item.numero.toString();
        console.log("Carpeta", rut_);
        var zip = new JSZip();
        const fold = zip.folder(rut_);
        documentos.map(d => {
            if (d.url !== null) {
                var reference = ref(storage, d.url);
                var filename = reference.name;
                console.log("Nombre Archivo", filename);
                fold.file(filename, urlToPromise(d.url), { binary: true });
            }
        });
        zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
            setPercentage(metadata.percent.toFixed(2));
            /* if (metadata.currentFile) {
                console.log("current file = " + metadata.currentFile);
            } */
        })
            .then(function (content) {
                saveAs(content, rut_ + ".zip");
                setPercentage(0);
            });
        setLoading(false);
    }

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="green" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3,
                    justifyContent: "start",
                    alignItems: "start",
                    display: "flex"
                }}>
                    <Button variant="contained" color="secondary" onClick={() => navigate('/personal/' + id_contrato)}>Volver</Button>
                </Box>
                <Box
                    sx={{
                        margin: 3,
                        padding: 2,
                        boxShadow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    {persona ? (
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        {persona.nombre.charAt(0)}
                                    </Avatar>
                                }
                                /* action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                } */
                                title={persona.nombre + " " + persona.apellido_paterno + " " + persona.apellido_materno}
                                subheader={persona.rut}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {persona.cargo}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : ""}
                </Box>
                <Box sx={{ margin: 3, justifyContent: "right", alignItems: "end", display: "flex" }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant='contained' color='success' onClick={openNewDocument}>Agregar Documento</Button>
                        <Button variant='contained' color='secondary' onClick={descargarArchivos}>Descargar Archivos</Button>
                    </Stack>
                </Box>
                {percentage > 0 ? (
                    <Box sx={{ width: '100%', margin: 3 }}>
                        <Typography variant='subtitle2'>
                            Descargando Archivos ...
                        </Typography>
                        <LinearProgressWithLabel value={percentage} />
                    </Box>
                ) : ""}
                <Box style={{
                    margin: 3,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                            title={"Personal"}
                            data={documentos}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                    <Divider></Divider>
                </Box>
            </Container>
            <Modal
                open={openModalDoc}
                onClose={closeModalDoc}
                center
            >
                <AppBar sx={{ backgroundColor: 'green' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                        Agregar Documento
                    </Typography>
                </AppBar>
                {loadDocumento ? <Box sx={{ width: '100%', marginTop: 3 }}>
                    <LinearProgress />
                </Box> : ""}
                <Box sx={{
                    margin: 3
                }}>
                    <Grid container spacing={2} mt={3}>
                        <Grid item xs={12} sm={6}>
                            {/* <TextField
                                name="nombre"
                                id="nombre"
                                label="Nombre Documento"
                                fullWidth
                                select
                                error={Boolean(formik.touched.nombre && formik.errors.nombre)}
                                onChange={formik.handleChange}
                                value={formik.values.nombre}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                            >
                                {maestroDocumento.map((md, index) => (
                                    <MenuItem key={index} value={md.nombre}>{md.nombre}</MenuItem>
                                ))}
                            </TextField> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={docAutoC}
                                onChange={(event, newValue) => formik.setFieldValue('nombre', newValue ? newValue.label : "", true)}
                                value={formik.values.nombre}
                                renderInput={(params) => <TextField {...params}
                                    label="Nombre Documento"
                                    helperText={formik.touched.nombre && formik.errors.nombre}
                                />}
                            />
                            <Typography variant="inherit" color="textSecondary">
                                {formik.touched.nombre && formik.errors.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Fecha Inicio"
                                    format='dd-MM-yyyy'
                                    //value={document.fecha_inicio ? document.fecha_inicio : ""}
                                    value={formik.values.fecha_inicio ? formik.values.fecha_inicio : ""}
                                    onChange={(newValue) => formik.setFieldValue('fecha_inicio', newValue, true)}
                                    renderInput={(params) => <TextField {...params} fullWidth
                                        error={Boolean(formik.touched.fecha_inicio && formik.errors.fecha_inicio)}
                                        helperText={formik.touched.fecha_inicio && formik.errors.fecha_inicio}
                                    />}
                                />
                            </LocalizationProvider>
                            <Typography variant="inherit" color="textSecondary">
                                {formik.touched.fecha_inicio && formik.errors.fecha_inicio}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DatePicker
                                    label="Fecha Termino"
                                    format='dd-MM-yyyy'
                                    value={formik.values.fecha_termino ? formik.values.fecha_termino : ""}
                                    onChange={(newValue) => formik.setFieldValue('fecha_termino', newValue, true)}
                                    renderInput={(params) => <TextField {...params} fullWidth
                                        error={Boolean(formik.touched.fecha_termino && formik.errors.fecha_termino)}
                                        helperText={formik.touched.fecha_termino && formik.errors.fecha_termino}
                                    />}
                                />
                            </LocalizationProvider>
                            <Typography variant="inherit" color="textSecondary">
                                {formik.touched.fecha_termino && formik.errors.fecha_termino}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' component='label' color='secondary' startIcon={<AttachFileIcon />}>
                                Cargar
                                <input hidden type="file" onChange={(e) => setArchivo(e.target.files[0])} />
                            </Button>
                            <Typography variant="inherit" color="textSecondary">
                                {archivo ? archivo.name : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ marginTop: 3, justifyContent: "right", alignItems: "end", display: "flex" }}>
                    <Stack direction="row" spacing={2}>
                        {formik.values && formik.values.id ? (
                            <Button variant='outlined' color='secondary' onClick={formik.handleSubmit}>Actualizar</Button>
                        ) : (
                            <Button variant='outlined' color='secondary' onClick={formik.handleSubmit}>Agregar</Button>
                        )}

                        <Button variant='outlined' color='error' onClick={closeModalDoc}>Cerrar</Button>
                    </Stack>
                </Box>
            </Modal>
            <Footer />
        </div>
    )
}

export default function DocumentoPersonaSnack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <DocumentoPersona />
        </SnackbarProvider>
    )
}