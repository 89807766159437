import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { v4 as uuidv4 } from 'uuid';

/** MUI */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import ResponsiveAppBar from "../components/navbar.component";

/** FireBase */
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

const Preguntas = () => {
    const [forms, setForms] = useState([{
        id: uuidv4(),
        pregunta: '',
        respuesta: '',
        puntaje: 0
    }]);
    const [nombre, setNombre] = useState('');
    const { id_contrato, id_procedimiento } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        localStorage.setItem("forms", forms);
        retreivePreguntas(id_procedimiento);
    }, []);

    async function retreivePreguntas(id_proc) {
        setLoading(true);
        var f_temp = [];
        const queryPre = await query(collection(FireBaseApp.db, "preguntas"), where('procedimiento', '==', id_proc));
        const preSnap = await getDocs(queryPre);
        if (!preSnap.empty) {
            preSnap.forEach((pDoc) => {
                const data_form = {
                    id: pDoc.data().id_pregunta,
                    pregunta: pDoc.data().pregunta,
                    respuesta: pDoc.data().respuesta,
                    puntaje: pDoc.data().puntaje,
                };
                f_temp.push(data_form);
            });
            setForms([...f_temp]);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const addForm = (formId) => {
        var temp_form = forms;
        const new_data = {
            id: uuidv4(),
            pregunta: 'Ingrese pregunta',
            respuesta: '',
            puntaje: 0
        };
        temp_form.push(new_data);
        setForms([...temp_form]);
    };

    const showNombre = () => {
        alert(nombre);
    };

    const handleForm = (e, id_form) => {
        console.log(id_form);
        const indx = forms.findIndex(v => v.id == id_form);
        console.log(indx);
        forms[indx].pregunta = e.target.value;
        setForms([...forms]);
    };

    const handleFormRespuesta = (e, id_form) => {
        console.log(id_form);
        const indx = forms.findIndex(v => v.id == id_form);
        console.log(indx);
        forms[indx].respuesta = e.target.value;
        setForms([...forms]);
    };

    const handleFormPuntaje = (e, id_form) => {
        const indx = forms.findIndex(v => v.id == id_form);
        forms[indx].puntaje = e.target.value;
        setForms([...forms]);
    };

    const addPreguntas = () => {
        try {
            setLoading(true);
            forms.map(async f => {
                console.log(f);
                //Si la pregunta ya existe, la editamos
                const queryPre = await query(collection(FireBaseApp.db, "preguntas"), where('id_pregunta', '==', f.id));
                const preSnap = await getDocs(queryPre);
                if (!preSnap.empty) {
                    //Editamos
                    const idPreDoc = preSnap.docs[0].id;
                    const refPre = await updateDoc(doc(FireBaseApp.db, "preguntas", idPreDoc), {
                        pregunta: f.pregunta,
                        puntaje: f.puntaje,
                        respuesta: f.respuesta
                    });
                } else {
                    //Agregamos
                    const refPre = await addDoc(collection(FireBaseApp.db, "preguntas"), {
                        id_pregunta: f.id,
                        pregunta: f.pregunta,
                        puntaje: f.puntaje,
                        respuesta: f.respuesta,
                        procedimiento: id_procedimiento
                    });
                }
            });
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const quitarForm = (id_form) => {
        var temp_form = forms;
        const indx = forms.findIndex(v => v.id === id_form);
        temp_form.splice(indx, indx >= 0 ? 1 : 0);
        setForms([...temp_form]);
    }

    return (
        <>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#2ECC71" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Typography variant="h3" sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)',
                    color: 'green',
                    fontWeight: 'bold',
                    margin: 3
                }}>CREAR EVALUACIÓN</Typography>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <IconButton onClick={addForm}>
                        <AddCircleIcon color="primary" fontSize="large" />
                    </IconButton>
                </Box>
                {forms.map((f) => (
                    <div id={f.id}>
                        <Box sx={{
                            margin: 3,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex"
                        }}>
                            <Stack direction="column" spacing={3} sx={{ width: "80%" }}>
                                <TextField
                                    label="Pregunta"
                                    name="pregunta"
                                    value={f.pregunta}
                                    onChange={(e) => handleForm(e, f.id)}
                                    fullWidth
                                />
                                <TextField
                                    label="Puntaje"
                                    name="puntaje"
                                    value={f.puntaje}
                                    onChange={(e) => handleFormPuntaje(e, f.id)}
                                    fullWidth
                                />
                                <FormControl>
                                    <FormLabel id="grupo-respuestas">Respuesta</FormLabel>
                                    <RadioGroup
                                        row
                                        id="grupo-respuestas"
                                        value={f.respuesta}
                                        onChange={(e) => handleFormRespuesta(e, f.id)}
                                    >
                                        <FormControlLabel value="V" control={<Radio />} label="Verdadero" />
                                        <FormControlLabel value="F" control={<Radio />} label="Falso" />
                                    </RadioGroup>
                                </FormControl>
                                <IconButton onClick={() => quitarForm(f.id)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Stack>
                        </Box>
                    </div>
                ))}
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    {/* <Button variant="contained" onClick={showNombre}>Show Nombre</Button> */}
                    <Button variant="contained" color="success" onClick={addPreguntas}>Agregar Preguntas</Button>
                </Box>
            </Container>
        </>
    )
}

export default Preguntas;