import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import {
    Box,
    Container,
    IconButton,
    Tooltip
} from "@mui/material";

/** ICONOS */
import PlagiarismIcon from '@mui/icons-material/Plagiarism';

/** FireBase */
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import ResponsiveAppBar from "../components/navbar.component";

const Brechas = () => {
    const { id_contrato } = useParams();
    const [brechas, setBrechas] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        retreiveBrechas(id_contrato);
    }, []);


    async function retreiveBrechas(id_contrato) {
        setLoading(true);
        var temp = [];
        const queryBrechas = await query(collection(FireBaseApp.db, "brechas"), where("id_contrato", "==", id_contrato), orderBy("fecha_ingreso", "desc"));
        const snapBrecha = await getDocs(queryBrechas);
        if (!snapBrecha.empty) {
            /* snapBrecha.forEach(async (bdoc) => {
                //Get item
                let dataItem = await getDoc(doc(FireBaseApp.db,"items",bdoc.data().id_item));
                var porc = parseInt(dataItem.data().porcentaje);
                console.log("Id item", dataItem.id, "Porcentaje Item", porc);
                if(porc != 100) {
                    temp.push({ id: bdoc.id, ...bdoc.data() });
                }
            }); */
            for (var i in snapBrecha.docs) {
                let dataItem = await getDoc(doc(FireBaseApp.db, "items", snapBrecha.docs[i].data().id_item));
                var porc = parseInt(dataItem.data().porcentaje);
                if (porc != 100) {
                    temp.push({ id: snapBrecha.docs[i].id, ...snapBrecha.docs[i].data() });
                }
            }
            setBrechas([...temp]);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const columns = [
        {
            accessorKey: 'brecha',
            header: 'Brecha',
        },
        {
            accessorKey: 'numero_item',
            header: 'Item',
        },
        {
            accessorKey: 'responsable',
            header: 'Responsable',
        },
        {
            accessorKey: 'fecha_limite',
            header: 'Fecha Limite',
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return (
                        <p>{moment(cell.getValue().toDate()).format("DD-MM-YYYY")}</p>
                    )
                } else {
                    return (
                        <p>Sin Fecha</p>
                    )
                }
            }
        }
    ];

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#2ECC71" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3
                }}>
                    <MaterialReactTable
                        data={brechas}
                        columns={columns}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <Tooltip title="Ver Item">
                                    <IconButton color='primary' onClick={() => navigate("/docsresso/" + row.original.id_contrato + "/" + row.original.id_item + "/" + row.original.id_subcategoria)}>
                                        <PlagiarismIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                </Box>
            </Container>
        </div>
    );
}

export default Brechas;