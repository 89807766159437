import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
    Box,
    Button,
    Container,
    IconButton,
    Stack,
    TextField
} from '@mui/material';
import MaterialReactTable from "material-react-table";

/** FireBase */
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import FireBaseApp from "../../firebase/firebase";

import ResponsiveAppBar from "../../components/navbar.component";

const ItemProcedimiento = () => {
    const { id_contrato, id_item } = useParams();
    const [personas, setPersonas] = useState([]);
    const [archivo, setArchivo] = useState('');
    const [nombreProc, setNombreProc] = useState('');
    const [codigoProc, setCodigoProc] = useState('');
    const [percent, setPercent] = useState(0);
    const storage = getStorage(FireBaseApp.app);
    const [loading, setLoading] = useState(false);
    const [idProc, setIdProc] = useState('');

    useEffect(() => {
        retreivePersonal(id_contrato);
    }, []);

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 20
        },
        {
            accessorKey: 'rut',
            header: 'Rut',
            size: 20
        },
        {
            accessorKey: 'nombre', //access nested data with dot notation
            header: 'Nombres',
            size: 20
        },
        {
            accessorKey: 'apellido_paterno', //access nested data with dot notation
            header: 'Apeliido Paterno',
            size: 20
        },
        {
            accessorKey: 'cargo', //access nested data with dot notation
            header: 'Cargo',
            size: 20
        },
    ];

    async function retreivePersonal(id_contrato) {
        var per_temp = [];
        const queryPersonas = await query(collection(FireBaseApp.db, "personas"), where("id_contrato", "==", id_contrato));
        const snapPersonas = await getDocs(queryPersonas);
        snapPersonas.forEach((doc) => {
            var data_per = { id: doc.id, ...doc.data() };
            per_temp.push(data_per);
        });
        setPersonas([...per_temp]);
    };

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#2ECC71" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <Stack direction="column" spacing={2} sx={{ width: "70%" }}>
                        <TextField
                            name="nombre"
                            label="Nombre Procedimiento"
                            fullWidth
                            onChange={(e) => setNombreProc(e.target.value)}
                        />
                        <TextField
                            name="codigo"
                            label="Codigo"
                            fullWidth
                            onChange={(e) => setCodigoProc(e.target.value)}
                        />
                        <IconButton component="label">
                            <CloudUploadIcon fontSize="large" />
                            <input hidden type="file" multiple onChange={(e) => setArchivo(e.target.files[0])} />
                        </IconButton>
                        {archivo !== '' ? (
                            <p>{archivo.name}</p>
                        ) : ("")}
                    </Stack>
                    {/* <Grid container spacing={2} xs>
                        <Grid item sm={6}>
                            <TextField
                                name="nombre"
                                label="Nombre Politica"
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <Button>SUBIR ARCHIVO</Button>
                        </Grid>
                    </Grid> */}
                </Box>
                <Box sx={{ margin: 3 }}>
                    <MaterialReactTable
                        data={personas}
                        columns={columns}
                        enableRowSelection
                        initialState={{ columnVisibility: { id: false } }}
                        renderTopToolbarCustomActions={({ table }) => {
                            /* const handleDeactivate = () => {
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    alert('deactivating ' + row.getValue('name'));
                                });
                            };

                            const handleActivate = () => {
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    alert('activating ' + row.getValue('name'));
                                });
                            }; */

                            const handleContact = async () => {
                                if (!archivo) {
                                    alert("Carge el archivo");
                                    return;
                                }
                                if (nombreProc === '' || codigoProc === '') {
                                    alert("Ingrese codigo y nombre de procedimiento");
                                    return;
                                }
                                var arr_user = [];
                                //Agregamos los usuarios a un arreglo
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    //alert('contact ' + row.getValue('rut') + ' ' + arr_id[0]);
                                    arr_user.push(row.getValue('id'));
                                });
                                //Creamos procedimiento
                                //Subimos el Archivo
                                setLoading(true);
                                const storageRef = ref(storage, `/procedimientos/${archivo.name}`);
                                const uploadTask = uploadBytesResumable(storageRef, archivo);

                                uploadTask.on(
                                    "state_changed",
                                    (snapshot) => {
                                        const percent = Math.round(
                                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                        );

                                        // update progress
                                        console.log("Procentaje", percent);
                                        setPercent(percent);
                                    },
                                    (err) => {
                                        console.error(err)
                                        setLoading(false);
                                    },
                                    () => {
                                        // download url
                                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                                            console.log(url);
                                            //Actualizamos el derivado
                                            const docRef = await addDoc(collection(FireBaseApp.db, "procedimientos"), {
                                                nombre: nombreProc,
                                                url: url,
                                                codigo: codigoProc,
                                                id_item: id_item,
                                                id_contrato: id_contrato
                                            });
                                            setIdProc(docRef.id);
                                            //Asociamos el procedimiento a los usuarios
                                            for (var i in arr_user) {
                                                console.log(arr_user[i], docRef.id);
                                                const procPersRef = await addDoc(collection(FireBaseApp.db,"procedimiento_persona"),{
                                                    id_procedimiento: docRef.id,
                                                    id_persona: arr_user[i]
                                                });
                                            }
                                            setLoading(false);
                                            setPercent(0);
                                        });
                                    }
                                );
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    {/* <Button
                                        color="error"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleActivate}
                                        variant="contained"
                                    >
                                        Activate
                                    </Button> */}
                                    <Button
                                        color="info"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleContact}
                                        variant="contained"
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            );
                        }}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default ItemProcedimiento;