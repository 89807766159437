// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getTranslatedError } from '@nafuzi/firebase-auth-error-translator';
import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";

const firebaseErrors = {
    'auth/user-not-found': 'No user corresponding to this email',
    'auth/email-already-in-use': 'The email address is already in use',
    'auth/invalid-email':"Correo Invalido",
    'auth/invalid-password': "Contraseña Invalida",
    'auth/invalid-credential': "Credenciales invalidas",
    'auth/invalid-login-credentials': "Credenciales Invalidas",
    'auth/too-many-requests':"El acceso a esta cuenta se ha deshabilitado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puedes restaurarlo inmediatamente restableciendo tu contraseña o puedes volver a intentarlo más tarde.",
  };

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDvXv4rETP4bBCj_cNjtLCWY2SGzfVW6Zs",
    authDomain: "gestiondoc-68f0b.firebaseapp.com",
    projectId: "gestiondoc-68f0b",
    storageBucket: "gestiondoc-68f0b.appspot.com",
    messagingSenderId: "777612296837",
    appId: "1:777612296837:web:dd7d05b1ea29affe4e5fec"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(firebaseErrors[err.code] || err.message);
        //throw firebaseErrors[err.code] || err.message;
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link enviado!");
    } catch (err) {
        console.error(err);
        alert(getTranslatedError('es', err.code));
    }
};

const logout = () => {
    localStorage.removeItem("usuario");
    signOut(auth);
};

const FireBaseApp = {
    app:app,
    db: db,
    auth: auth,
    logInWithEmailAndPassword,
    logout,
    sendPasswordReset
}

export default FireBaseApp;