import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useEffect } from 'react';
import FireBaseApp from "../firebase/firebase";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Derechos Reservados © '}
            <Link color="inherit" href="https://metaprime.cl">
                Meta Prime
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Welcome() {
    const [contratos, setContratos] = useState([]);
    const [contrato, setContrato] = useState('');
    const navigate = useNavigate();
    const [user, loading_user, error] = useAuthState(FireBaseApp.auth);
    const [usuario, setUsuario] = React.useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading_user) {
            return;
        }
        if (!user) {
            navigate("/");
        } else {
            //retreiveContratos();
            retreiveUser(user.uid);
        }
    }, []);

    async function retreiveContratos(id_c, id_e) {
        var contra_temp = [];
        if (id_c === "") {
            const contraQuery = await query(collection(FireBaseApp.db, "contrato"), where("id_empresa", "==", id_e));
            const contraSnap = await getDocs(contraQuery);
            contraSnap.forEach((docContra) => {
                var contra_data = { id: docContra.id, ...docContra.data() };
                contra_temp.push(contra_data);
            });
            setContratos([...contra_temp]);
        } else {
            //Agregamos los contratos asociados al usuario
            for(var c in id_c) {
                const dataCont = await doc(FireBaseApp.db,"contrato",id_c[c]);
                const snapCont = await getDoc(dataCont);
                contra_temp.push({id: snapCont.id, ...snapCont.data()});
            }
            setContratos([...contra_temp]);
        }

    };

    async function retreiveUser(uid) {
        setLoading(true);
        const queryUsers = await query(collection(FireBaseApp.db, "users"), where("uid", "==", uid));
        const snapUsers = await getDocs(queryUsers);
        setUsuario({ id: snapUsers.docs[0].id, ...snapUsers.docs[0].data() });
        localStorage.setItem("usuario", JSON.stringify({id: snapUsers.docs[0].id, ...snapUsers.docs[0].data()}));
        retreiveContratos(snapUsers.docs[0].data().id_contrato, snapUsers.docs[0].data().id_empresa);
        setLoading(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var cont = data.get('contrato');
        if (cont !== '') {
            //Ingreso
            if (usuario.perfil === "analista_rrhh") {
                navigate("/personal/" + cont);
            } else {
                navigate("/inicio/" + cont + "/inicio");
            }
        } else {
            alert("Debe Seleccionar el contrato");
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="green" />
            ) : ""}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <img src='img/icon1024_fondo.png' alt='Meta Prime' style={{
                        width: 100,
                        height: 100,
                        m: 1,
                        bgcolor: 'secondary.main'
                    }}
                    />
                    <Typography component="h1" variant="h5">
                        Seleccione Contrato
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="contrato"
                            label="Seleccione Contrato"
                            name="contrato"
                            autoComplete="Nombre contrato"
                            autoFocus
                            select
                        >
                            {contratos.map((c, index) => (
                                <MenuItem key={index} value={c.id}>{c.abreviado}</MenuItem>
                            ))}
                        </TextField>
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color='success'
                        >
                            Ingresar
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </Box>
                </Box>
                {usuario.perfil == "gerencia" ? (
                    <Box sx={{
                        margin: 3,
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button variant='contained' color='success' onClick={()=>navigate("/reportegerencial")}>REPORTE GERENCIAL</Button>
                    </Box>
                ):""}
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}