import MaterialReactTable from "material-react-table";
import React, { useEffect, useState } from "react";

/** MUI */
import { Box, Container } from "@mui/material";

/** FireBase */
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

const Categorias = () => {
    const [categorias, setCategorias] = useState([]);

    useEffect(()=>{
        retreiveCategorias();
    },[]);

    async function retreiveCategorias() {
        var cate_temp = [];
        const docs = await collection(FireBaseApp.db, "categoria");
        const snapCate = await getDocs(docs);
        snapCate.forEach((doc) => {
            var cate = { id: doc.id, ...doc.data() };
            cate_temp.push(cate);
        });
        setCategorias([...cate_temp]);
    }

    const columns = [
        {
            accessorKey: 'nombre', //access nested data with dot notation
            header: 'Nombre',
            size: 400
        },
    ];

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        console.log(row);
        console.log(values.nombre);
        console.log(row.original.id);
        const catRef = doc(FireBaseApp.db,"categoria",row.original.id);
        await updateDoc(catRef, {
            nombre: values.nombre
        });
        retreiveCategorias();
        exitEditingMode();
      };


    return (
        <Container>
            <Box>
                <MaterialReactTable
                    columns={columns}
                    data={categorias}
                    editingMode="modal"
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                />
            </Box>
        </Container>
    )

}

export default Categorias;