import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";

/** Iconos */
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";

import {
    MaterialReactTable
} from "material-react-table";
import ResponsiveAppBar from "../../components/navbar.component";
import FireBaseApp from "../../firebase/firebase";

const VerItem = () => {
    const { id_contrato, id_item, id_subcategoria } = useParams();
    const [item, setItem] = useState('');
    const [ponderado, setPonderado] = useState('');
    const [politicas, setPoliticas] = useState([]);
    const navitate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [valorPonderado, setValorPonderado] = useState(0);

    useEffect(() => {
        retreivePoliticas(id_item);
        retreiveItem(id_item);
    }, []);

    async function retreiveItem(id_item) {
        try {
            setLoading(true);
            const refItem = await doc(FireBaseApp.db, "items", id_item);
            const docItem = await getDoc(refItem);
            setItem({ id: docItem.id, ...docItem.data() });
            //Get Ponderado
            const queryPond = query(collection(FireBaseApp.db, "ponderado"), where("id_item", "==", docItem.id));
            const snapPond = await getDocs(queryPond);
            if (!snapPond.empty) {
                setPonderado({ id: snapPond.docs[0].id, ...snapPond.docs[0].data() });
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    async function retreivePoliticas(id_item) {
        setLoading(true);
        var arr_temp = [];
        const queryPol = await query(collection(FireBaseApp.db, "documentos_resso"), where("item", "==", id_item));
        const snapPol = await getDocs(queryPol);
        if (!snapPol.empty) {
            snapPol.forEach((document) => {
                var data = { id: document.id, ...document.data() };
                arr_temp.push(data);
            });
            setPoliticas([...arr_temp]);
            setLoading(false);
        };
    };

    const columns = [
        {
            accessorKey: 'nombre',
            header: 'Nombre',
        },
        {
            accessorKey: 'url',
            header: 'Descarga',
            Cell: ({ cell }) => {
                return (
                    <IconButton onClick={() => window.open(cell.getValue(), "_blank")}>
                        <PictureAsPdfIcon color="success" />
                    </IconButton>
                )
            }
        },
        {
            accessorKey: 'fecha_creacion',
            header: 'Fecha Creación',
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return (
                        <p>{moment(cell.getValue().toDate()).format("DD-MM-YYYY")}</p>
                    )
                } else {
                    return (
                        <p>Sin Fecha</p>
                    )
                }
            }
        },
        {
            accessorKey: 'fecha_vencimiento',
            header: 'Fecha Vencimiento',
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return (
                        <p>{moment(cell.getValue().toDate()).format("DD-MM-YYYY")}</p>
                    )
                } else {
                    return (
                        <p>Sin Fecha</p>
                    )
                }

            }
        },
        {
            accessorKey: 'nivel_cumplimiento',
            header: 'Nivel de Cumplimiento',
        },
        {
            accessorKey: 'observacion',
            header: 'Observación',
        },
    ];

    const handlePonderador = (event) => {
        setValorPonderado(event.target.value);
        event.target.checked = true;
    };

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="green" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "start",
                        alignItems: "start",
                        display: "flex"
                    }}>
                        <Button variant="contained" color="secondary" onClick={() => navitate("/inicio/" + id_contrato + "/" + id_subcategoria)}>Volver</Button>
                    </Box>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }}>
                        <Typography variant="h6">
                            {item.numero + " - " + item.nombre}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Card sx={{
                        width: "100%",
                        boxShadow: 3
                    }}>
                        <CardContent>
                            {ponderado !== "" ? (
                                <>
                                    <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">Ponderado</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={valorPonderado}
                                        >
                                            {ponderado.indicadores.map((ind, index) => {
                                                return (
                                                    <>
                                                        <FormControlLabel key={index} value={ind.valor} control={<Radio disabled />} label={ind.nombre} checked={ind.estado} />
                                                    </>
                                                )

                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    <Box sx={{
                                        margin: 3,
                                        justifyContent: "end",
                                        alignContent: "end",
                                        alignItems: "end",
                                        display: "flex"
                                    }}>
                                        {/* <Button variant="contained" onClick={actualizarPonderado}>Actualizar</Button> */}
                                    </Box>
                                </>
                            ) : ""}
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "end",
                    alignContent: "end",
                    alignItems: "end",
                    display: "flex"
                }}>
                    <Card sx={{
                        width: "100%",
                        boxShadow: 3
                    }}>
                        <CardContent>
                            <Typography variant="h5" sx={{
                                textAlign: "left"
                            }}>Observaciones:</Typography>
                            <Typography variant="h6">
                                {item.observacion ? item.observacion : ""}
                            </Typography>
                            {/* <TextField
                                rows={5}
                                multiline
                                fullWidth
                                label="Observaciones del Punto"
                                onChange={(e) => setObservacionPunto(e.target.value)}
                            /> */}
                            <Box sx={{
                                margin: 3,
                                justifyContent: "end",
                                alignContent: "end",
                                alignItems: "end",
                                display: "flex"
                            }}>
                                {/* <Button variant="contained" onClick={enviarObservacionPunto}>Enviar</Button> */}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    //display: "flex"
                }}>
                    <MaterialReactTable
                        data={politicas}
                        columns={columns}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableSorting={true}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default VerItem;
