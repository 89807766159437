import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { AppBar, Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";

/** FireBase */
import { addDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import FireBaseApp from "../firebase/firebase";
import emailService from '../services/email.service';

const Resultado = (props) => {
    const { id_contrato } = props;
    const [sumaPonderacion, setSumaPonderacion] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [porcentajeTerrno, setPorcentajeTerreno] = useState('');
    const [archivo, setArchivo] = useState('');
    const [brecha, setBrecha] = useState('');
    const [correoBrecha, setCorreoBrecha] = useState('');
    const [responsableBrecha, setResponsableBrecha] = useState('');
    const [percent, setPercent] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const storage = getStorage(FireBaseApp.app);
    const [usuario, setUsuario] = useState('');
    const [urlTerreno, setUrlTerreno] = useState('');

    useEffect(() => {
        calcularResultados();
        retreiveAuditoriaTerreno(id_contrato);
        setUsuario(JSON.parse(localStorage.getItem("usuario")));
    }, []);

    const closeModal = () => setOpenModal(false);

    async function calcularResultados() {
        let isMounted = true;
        setLoading(true);
        const doFetch = async () => {
            var cate_temp = [];
            const catRef = await query(collection(FireBaseApp.db, "categoria"), where("id_contrato", "==", id_contrato));
            const snapCats = await getDocs(catRef);
            if (!snapCats.empty) {
                snapCats.forEach(async (docCat) => {
                    //Sumamos los resultados parciales de las subcategorias
                    var ponderacion_temp = 0;
                    var resultado_parcial = 0;
                    var porc_item = 0;
                    const querySubcat = await query(collection(FireBaseApp.db, "subcategoria"), where("id_categoria", "==", docCat.id));
                    const snapSubCat = await getDocs(querySubcat);
                    for (var sc in snapSubCat.docs) {
                        //ponderacion_temp = ponderacion_temp + snapSubCat.docs[sc].data().ponderacion_parcial;
                        const queryItems = await query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", snapSubCat.docs[sc].id));
                        const snapItems = await getDocs(queryItems);
                        snapItems.forEach((docI) => {
                            if (docI.data().resultado) {
                                resultado_parcial = resultado_parcial + docI.data().resultado;
                            }
                        });
                        ponderacion_temp = ponderacion_temp + snapSubCat.docs[sc].data().ponderacion_parcial;
                        //resultado_parcial = await calculoNivelCumplimiento(snapSubCat.docs[sc].id);//resultado_parcial+docSubCat.data().resultado_parcial;
                        porc_item = Math.round((resultado_parcial * 100) / ponderacion_temp, -2);
                    }
                    /* snapSubCat.forEach(async (docSubCat) => {
                        ponderacion_temp = ponderacion_temp + docSubCat.data().ponderacion_parcial;
                        const queryItems = await query(collection(FireBaseApp.db,"items"),where("id_subcategoria","==",docSubCat.id));
                        const snapItems = await getDocs(queryItems);
                        snapItems.forEach((docI)=>{
                            if(docI.data().resultado) {
                                resultado_parcial = resultado_parcial + docI.data().resultado;
                            }
                        });
                        //resultado_parcial = await calculoNivelCumplimiento(docSubCat.id);//resultado_parcial+docSubCat.data().resultado_parcial;
                        porc_item = Math.round((resultado_parcial*100)/ponderacion_temp, -2);
                    }); */
                    var cat_data = { id: docCat.id, ...docCat.data(), ponderacion_categoria: ponderacion_temp, nivel_cumplimiento: resultado_parcial, porc_item: porc_item };
                    cate_temp.push(cat_data);
                    // order an array of objects with name
                    cate_temp.sort(function (a, b) {
                        if (a.nombre < b.nombre) {
                            return -1;
                        }
                        if (a.nombre > b.nombre) {
                            return 1;
                        }
                        return 0;
                    });
                    if (isMounted) {
                        setCategorias([...cate_temp]);
                        //Sumamos los niveles de cumplimiento
                        var sum_nc = 0;
                        for (var sc in cate_temp) {
                            sum_nc = sum_nc + cate_temp[sc].nivel_cumplimiento;
                        }
                        setSumaPonderacion(sum_nc);
                        setLoading(false);
                    }
                });
            } else {
                setLoading(false);
                alert("No se cargado el nada en este Contrato");
            }
        }

        doFetch() // start the async work
            .catch((err) => {
                if (!isMounted) {
                    setLoading(false);
                    return;
                } // unmounted, ignore.
                // TODO: Handle errors in your component
                console.error("Error al cargar los datos", err);
            });


        return () => {
            isMounted = false;
            setLoading(false);
        };

    };

    async function retreiveAuditoriaTerreno(id_contrato) {
        const refAt = await query(collection(FireBaseApp.db, "auditoria_terreno"), where("id_contrato", "==", id_contrato), orderBy("fecha_ingreso", "desc"));
        const dataAt = await getDocs(refAt);
        if (!dataAt.empty) {
            const data_auditoria = { id: dataAt.docs[0].id, ...dataAt.docs[0].data() };
            setPorcentajeTerreno(data_auditoria.porcentaje_terreno);
            setUrlTerreno(data_auditoria.url);
        }
    }

    async function sumaPonderaciones() {
        var suma = 0;
        const catRef = await collection(FireBaseApp.db, "categoria");
        const snapCats = await getDocs(catRef);
        snapCats.forEach((docC) => {
            var nc = 0;
            if (isNaN(docC.data().nivel_cumplimiento)) {
                nc = 0;
            } else {
                nc = docC.data().nivel_cumplimiento;
            }
            suma = suma + nc;
            setSumaPonderacion(suma);
        });
    };

    async function calculoNivelCumplimiento(id_subcategoria) {
        var resultado_temp = 0;
        const queryItems = await query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", id_subcategoria));
        const snapItems = await getDocs(queryItems);
        if (!snapItems.empty) {
            snapItems.forEach((docI) => {
                if (docI.data().resultado) {
                    return resultado_temp = resultado_temp + docI.data().resultado;
                }
            });
        }
        //return resultado_temp;
    };

    function calculoNC(id_subcategoria) {
        var res_temp = 0;
        const queryItems = query(collection(FireBaseApp.db, "items"), where("id_subcategoria", "==", id_subcategoria));
        const snapItems = getDocs(queryItems);
        snapItems.then((response) => {
            var data = response.docs;
            data.forEach((element) => res_temp += element.data().resultado);
        });
        return res_temp;
    };

    const ingresarBrechaTerreno = () => {
        if (!porcentajeTerrno) {
            alert("Debe ingresar un porcentaje");
            return;
        }
        if (!archivo) {
            alert("Debe adjuntar un archivo!");
            return;
        }
        closeModal();
        setLoading(true);
        const data_brecha = {
            porcentaje_terreno: porcentajeTerrno,
            brecha_terreno: brecha,
            correo_brecha: correoBrecha,
            brecha_responsable: responsableBrecha,
            id_contrato: id_contrato,
        }
        //Cargamos el archivo
        const storageRef = ref(storage, `/brechas_terreno/${archivo.name}`);
        const uploadTask = uploadBytesResumable(storageRef, archivo);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                console.log("Procentaje", percent);
                //setPercent(percent);
            },
            (err) => {
                console.error(err)
                setLoading(false);
                setArchivo('');
                //enqueueSnackbar("Ocurrio un error al cargar el Documento !!", { variant: 'error' });
                alert("Ocurrio un error al cargar el Documento !!");
                closeModal();
            },
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                    console.log(url);
                    setUrlTerreno(url);
                    //Actualizamos el derivado
                    const docRef = await addDoc(collection(FireBaseApp.db, "auditoria_terreno"), {
                        porcentaje_terreno: porcentajeTerrno,
                        brecha_terreno: brecha,
                        correo_brecha: correoBrecha,
                        brecha_responsable: responsableBrecha,
                        id_contrato: id_contrato,
                        url: url,
                        fecha_ingreso: new Date(),
                    });
                    calcularResultados();
                    setLoading(false);
                    setArchivo('');
                    //enqueueSnackbar("Documento Cargado Correctamente !!", { variant: 'success' });
                    if (correoBrecha !== '') {
                        //Enviamos correo
                        const data_mail = {
                            email: correoBrecha,
                            mensaje: creatHtml(brecha),
                        }
                        setLoading(true);
                        emailService.sendEmail(data_mail)
                            .then(resp => {
                                setLoading(false);
                                alert("Correo enviado correctamente!!");
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                alert("Ocurrio un error al enviar el mail!!");
                            });
                    }
                    alert("Documento Cargado Correctamente !!");
                    //setPercent(0);
                    //Limpiamos los formularios
                    setBrecha('');
                    setCorreoBrecha('');
                    setResponsableBrecha('');
                });
            }
        );
    };

    function creatHtml(brecha) {
        var html = `
        <p>Estimado: </p><br />
        <p>El presente correo es para informar que usted tiene una brecha de auditoria en terreno la cual debe poner atención y acción para el cumplimiento del programa RESSO:</p><br />
        <p>Brecha: <strong>${brecha}<strong></p><br />
        `
        return html;
    };

    const abriRuta = (link) => {
        if (link !== null) {
            window.open(link);
        }
    };

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
            ) : ""}
            <Box sx={{ margin: 2, justifyContent: "center", alignItems: "center", position: "relative" }}>
                {/* {JSON.stringify(categorias, null, null, 2)} */}
                <Box sx={{ overflow: "auto" }}>
                    <Box sx={{ margin: 3, justifyContent: "center", alignItems: "center", display: 'table', tableLayout: 'fixed' }}>
                        <Table sx={{ marginTop: 2, width: 700 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ textAlign: "center", backgroundColor: "gray", fontWeight: "bold", color: "white" }}>
                                        Resultado Final
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Resultado Auditoria Documental
                                    </TableCell>
                                    <TableCell>
                                        {sumaPonderacion * 0.5}%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Resultado Auditoria Terreno
                                    </TableCell>
                                    <TableCell>
                                        {porcentajeTerrno ? (porcentajeTerrno * 0.5) + "%" : "0"} {urlTerreno ? (<IconButton onClick={() => abriRuta(urlTerreno)} color='primary'><PictureAsPdfIcon /></IconButton>): ""}
                                        {usuario && (usuario.uid == "wcPBGfVE1reUESh5UhYCFYkBi9t2" || usuario.uid == "ZVvFJRNnxaNsxry0bAodkRNUvOb2") ? (
                                            <Button variant="outlined" onClick={() => setOpenModal(true)}>% Terreno</Button>
                                        ) : ""}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Descuento por Hallazgo Detectado:
                                    </TableCell>
                                    <TableCell>
                                        0%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell rowSpan={3}>
                                        Clasificación Auditoria
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {(sumaPonderacion * 0.5) + 50}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {((sumaPonderacion * 0.5) + 50) >= 85 ? (
                                        <TableCell sx={{ backgroundColor: "green", color: "white" }}>
                                            ACEPTABLE
                                        </TableCell>
                                    ) : ((sumaPonderacion * 0.5) + 50) >= 64 ? (
                                        <TableCell sx={{ backgroundColor: "yellow", color: "black" }}>
                                            MODERADO
                                        </TableCell>
                                    ) : (
                                        <TableCell sx={{ backgroundColor: "red", color: "white" }}>
                                            INACEPTABLE
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
                <Box sx={{ overflow: "auto" }}>
                    <Box sx={{ margin: 3, justifyContent: "center", alignItems: "center", display: 'table', tableLayout: 'fixed' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Evaluación Documental</TableCell>
                                    <TableCell>Ponderación</TableCell>
                                    <TableCell>Nivel Cumplimiento</TableCell>
                                    <TableCell>% Por Item</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categorias.map((c, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{c.nombre}</TableCell>
                                        <TableCell>{c.ponderacion_categoria}</TableCell>
                                        <TableCell>{c.nivel_cumplimiento}</TableCell>
                                        <TableCell>{c.porc_item}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Box>
            <Modal
                open={openModal}
                onClose={closeModal}
                center
            >
                <AppBar sx={{ backgroundColor: 'green' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center" style={{ marginBottom: 10 }}>
                        Ingresar % de Auditoria Laboral
                    </Typography>
                </AppBar>
                <Box sx={{
                    margin: 3
                }}>
                    <Grid container spacing={2} mt={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="% Auditoria Terreno"
                                name="porcentaje_terreno"
                                id="porcentaje_terreno"
                                fullWidth
                                onChange={(e) => setPorcentajeTerreno(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant='contained' component='label' color='secondary' startIcon={<AttachFileIcon />}>
                                Cargar Archivo
                                <input hidden type="file" onChange={(e) => setArchivo(e.target.files[0])} />
                            </Button>
                            <Typography variant="inherit" color="textSecondary">
                                {archivo ? archivo.name : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    {porcentajeTerrno !== '' & porcentajeTerrno < 100 ? (
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Brecha"
                                    name="brecha_terreno"
                                    id="brecha_terreno"
                                    fullWidth
                                    onChange={(e) => setBrecha(e.target.value)}
                                    rows={4}
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Correo Responsable"
                                    name="correo_brecha"
                                    id="correo_brecha"
                                    fullWidth
                                    onChange={(e) => setCorreoBrecha(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Responsable"
                                    name="brecha_responsable"
                                    id="brecha_responsable"
                                    fullWidth
                                    onChange={(e) => setResponsableBrecha(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    ) : ""}
                    <Box sx={{
                        margin: 3,
                        justifyContent: "right",
                        alignContent: "end",
                        alignItems: "end",
                        display: "flex"
                    }}>
                        <Button variant="contained" color="success" onClick={ingresarBrechaTerreno}>Ingresar</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default Resultado;