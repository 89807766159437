import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import AddReactionIcon from '@mui/icons-material/AddReaction';
import {
    Box,
    Button,
    Container,
    IconButton,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** ICONOS */
import { Delete } from '@mui/icons-material';

/** FireBase */
import { collection, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

import ResponsiveAppBar from "../components/navbar.component";

const Procedimientos = () => {
    const { id_contrato } = useParams();
    const [procedimientos, setProcedimientos] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const confirm = useConfirm();
    const [id_item, setIdItem] = useState('');

    useEffect(() => {
        retreiveProcedimientos(id_contrato);
    }, []);

    async function retreiveProcedimientos(idc) {
        setLoading(true);
        var temp_proc = [];
        const queryProc = await query(collection(FireBaseApp.db, "procedimientos"), where('id_contrato', '==', idc));
        const refProc = await getDocs(queryProc);
        refProc.forEach((doc) => {
            const data_proc = { id: doc.id, ...doc.data() };
            temp_proc.push(data_proc);
        });
        setProcedimientos([...temp_proc]);
        setLoading(false);
    }

    const columns = [
        {
            accessorKey: 'codigo',
            header: 'Codigo',
            size: 20
        },
        {
            accessorKey: 'nombre',
            header: 'Nombre',
            size: 20
        },
        {
            accessorKey: 'id',
            header: 'Evaluación',
            size: 20,
            Cell: ({ cell, row }) => {
                return (
                    <IconButton onClick={() => addPreguntas(cell.getValue())}><AddReactionIcon sx={{ color: 'green' }} /></IconButton>
                )
            }
        },
        {
            accessorKey: 'id_1',
            header: 'Asociados',
            size: 20,
            Cell: ({ cell, row }) => {
                return (
                    <Button color="success" variant="contained" onClick={() => verAsociados(row.original.id)}>Asociados</Button>
                )
            }
        }
    ];

    const addPreguntas = (id_proc) => {
        navigate("/preguntas/" + id_contrato + "/" + id_proc);
    };

    const verAsociados = (id_proc) => {
        navigate("/procedimientos_asociados/" + id_contrato + "/" + id_proc);
    };

    const handleDelete = async (deletedIndex, id_proc) => {
        console.log(deletedIndex);
        /* const result = await confirm({description: "Esta seguro de Eliminar este Procedimiento", title: "Lo vas a Eliminar??"});
        if(result) {
            setProcedimientos(procedimientos.filter((item, index) => index !== deletedIndex));
        } */
        confirm({ description: "Esta seguro de Eliminar este Procedimiento", title: "Vas a Eliminar un Procedimiento??" })
            .then(() => {
                setProcedimientos(procedimientos.filter((item, index) => index !== deletedIndex));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const ingresarProcedimiento = () => {
        if(!id_item) {
            alert("Debe ingresar el número de Item");
            return;
        }
        navigate("/itemprocedimiento/" + id_contrato + "/" + id_item);
    }

    return (
        <>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#2ECC71" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3
                }}>
                    <Box sx={{
                        margin: 3,
                        justifyContent: "end",
                        alignItems: "end",
                        display: "flex"
                    }}>
                        <Stack spacing={3} direction="row">
                            <TextField
                                label="N° Item"
                                name="n_item"
                                fullWidth
                                onChange={(e)=>setIdItem(e.target.value)}
                            />
                            <Button variant="contained" color="secondary" onClick={ingresarProcedimiento}>Ingresar Procedimiento</Button>
                        </Stack>
                        
                    </Box>
                    <MaterialReactTable
                        columns={columns}
                        data={procedimientos}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <Tooltip title="Eliminar Procedimiento">
                                    <IconButton color='success' onClick={() => handleDelete(row.index, row.original.id)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                </Box>
            </Container>
        </>
    )

}

export default Procedimientos;