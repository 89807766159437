import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

/** MUI */
import {
    Box,
    Button,
    Container,
    Grid,
    TextField
} from '@mui/material';
import MaterialReactTable from "material-react-table";

/** FireBase */
import { collection, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../../firebase/firebase";

import ResponsiveAppBar from "../../components/navbar.component";

const Item3 = () => {
    const { id_contrato } = useParams();
    const [personas, setPersonas] = useState([]);

    useEffect(() => {
        retreivePersonal(id_contrato);
    }, []);

    const columns = [
        {
            accessorKey: 'rut',
            header: 'Rut',
            size: 20
        },
        {
            accessorKey: 'nombres', //access nested data with dot notation
            header: 'Nombres',
            size: 20
        },
        {
            accessorKey: 'ap_paterno', //access nested data with dot notation
            header: 'Apeliido Paterno',
            size: 20
        },
    ];

    async function retreivePersonal(id_contrato) {
        var per_temp = [];
        const queryPersonas = await query(collection(FireBaseApp.db, "personas"), where("id_contrato", "==", id_contrato));
        const snapPersonas = await getDocs(queryPersonas);
        snapPersonas.forEach((doc) => {
            var data_per = { id: doc.id, ...doc.data() };
            per_temp.push(data_per);
        });
        setPersonas([...per_temp]);
    };

    return (
        <div>
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{ margin: 3 }}>
                    <Grid container spacing={2} xs>
                        <Grid item sm={6}>
                            <TextField
                                name="nombre"
                                label="Nombre Politica"
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <Button>SUBIR ARCHIVO</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ margin: 3 }}>
                    <MaterialReactTable
                        data={personas}
                        columns={columns}
                        enableRowSelection
                        renderTopToolbarCustomActions={({ table }) => {
                            const handleDeactivate = () => {
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    alert('deactivating ' + row.getValue('name'));
                                });
                            };

                            const handleActivate = () => {
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    alert('activating ' + row.getValue('name'));
                                });
                            };

                            const handleContact = () => {
                                table.getSelectedRowModel().flatRows.map((row) => {
                                    alert('contact ' + row.getValue('nombres'));
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleActivate}
                                        variant="contained"
                                    >
                                        Activate
                                    </Button>
                                    <Button
                                        color="info"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={handleContact}
                                        variant="contained"
                                    >
                                        Contact
                                    </Button>
                                </div>
                            );
                        }}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default Item3;