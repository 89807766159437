import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from 'react-router-dom';

/** MUI */
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import { Box, Stack, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { TreeItem, TreeView } from "@mui/x-tree-view";

import { treeItemClasses } from '@mui/x-tree-view/TreeItem';


/** MUI ICONS */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../firebase/firebase";

/** Componentes */
import Footer from "../components/footer.component";
import ItemResso from "../components/itemresso.component";
import ResponsiveAppBar from "../components/navbar.component";

/** Paginas */
import Resultado from "./resultado.page";

const cabezaArbol = ["A", "B", "C", "D"];
const subCabezaArbol = ["A1", "A2", "C1", "C2", "C3", "D1", "D2"];

//const categorias = [{id:"A", nombre: "A"},{id:"B", nombre: "B"}];
//const subcategodias =[{id:"A1",nombre: "A1", id_categria: "A"},{id:"A2",nombre: "A2", id_categria: "A"},{id:"B1",nombre: "B1", id_categria: "B"},]

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

const Arbol = () => {
    const { id_contrato, seltree } = useParams();
    const [activeTree, setActiveTree] = useState('');
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [user, loading_user, error] = useAuthState(FireBaseApp.auth);
    const [usuario, setUsuario] = useState('');
    const navigate = useNavigate();
    const [contrato, setContrato] = useState('');

    useEffect(() => {
        const usuar = JSON.parse(localStorage.getItem("usuario"));
        if (seltree !== "inicio") {
            setActiveTree(seltree);
        }
        retreiveCategorias();
        retreiveUser(usuar.uid);
        retreiveContrato(id_contrato);
    }, []);

    async function retreiveContrato(id_cont) {
        const contData = await getDoc(doc(FireBaseApp.db,"contrato",id_cont));
        setContrato(contData.data());
    };

    async function retreiveCategorias() {
        var cate_temp = [];
        var sub_cate_temp = [];
        const qCat = await query(collection(FireBaseApp.db, "categoria"), where('id_contrato', '==', id_contrato));
        const sCat = await getDocs(qCat);
        sCat.forEach(async (doc) => {
            var cate = { id: doc.id, ...doc.data() };
            cate_temp.push(cate);

            //Agregamos las subcategorias
            const qSCat = await query(collection(FireBaseApp.db, "subcategoria"), where('id_categoria', '==', doc.id));
            const subCatSnap = await getDocs(qSCat);
            subCatSnap.forEach((scatDoc) => {
                var sub_cate = { id: scatDoc.id, ...scatDoc.data() };
                sub_cate_temp.push(sub_cate);
            });
            setSubcategorias([...sub_cate_temp]);
        })
        setCategorias([...cate_temp]);
    };

    async function retreiveUser(uid) {
        const queryUser = await query(collection(FireBaseApp.db,"users"), where("uid","==",uid));
        const snapUser = await getDocs(queryUser);
        if(!snapUser.empty) {
            setUsuario({id: snapUser.docs[0].id, ...snapUser.docs[0].data()});
        } else {
            alert("Error al cargar usuario !!");
            return;
        }
    };

    const handleSelect = (event, selectedTree) => {
        setActiveTree(selectedTree);
    };

    const renderArbol = (treeActivo) => {
        return (
            <div>
                {(() => {
                    const options = [];

                    for (let s in subcategorias) {
                        options.push(<ItemResso activeTree={treeActivo} selectedTree={subcategorias[s].id} id_subcategoria={subcategorias[s].id}></ItemResso>);
                    }

                    return options;
                })()}
            </div>
        )
    }

    return (
        <div>
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Box sx={{ marginTop: 3 }}>
                <Typography variant="h5" sx={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "green",
                    fontWeight: "bold"
                }}>
                    RESSO - {contrato.abreviado}
                </Typography>
                <Stack spacing={3} direction="row">
                    <Box width="20%">
                        <TreeView
                            defaultCollapseIcon={<ArrowDropDownIcon />}
                            defaultExpandIcon={<ArrowRightIcon />}
                            defaultExpanded={['3']}
                            onNodeSelect={handleSelect}
                            defaultEndIcon={<div style={{ width: 24 }} />}
                            sx={{ height: 'auto', flexGrow: 1 }}
                        >
                            {categorias.map((ca, index) => (
                                <StyledTreeItem key={index} nodeId={ca.id} labelText={ca.titulo} labelIcon={CategoryOutlinedIcon} sx={{ fontSize: '1rem', }}>
                                    {/* <TreeItem nodeId={subCabezaArbol[index]} label={subCabezaArbol[index]}></TreeItem> */}
                                    {subcategorias.filter(v => v.id_categoria === ca.id).map((sc, index) => (
                                        <StyledTreeItem
                                            key={index}
                                            nodeId={sc.id}
                                            labelText={sc.titulo}
                                            labelIcon={ClassOutlinedIcon}
                                            color="#1a73e8"
                                            bgColor="#e8f0fe"
                                            colorForDarkMode="#B8E7FB"
                                            bgColorForDarkMode="#071318"
                                        >

                                        </StyledTreeItem >
                                    ))}
                                </StyledTreeItem >
                            ))}
                        </TreeView>
                    </Box>
                    <Box width="80%">
                        {activeTree !== "" && activeTree !== "inicio" ? (
                            renderArbol(activeTree)

                        ) : (
                            <Resultado id_contrato={id_contrato} />
                        )}

                    </Box>
                </Stack>
            </Box>
            <Footer />
        </div>
    )
}

export default Arbol;