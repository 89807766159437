import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import MaterialReactTable from "material-react-table";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

/** FireBase */
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import FireBaseApp from "../../firebase/firebase";

import ResponsiveAppBar from "../../components/navbar.component";

const VerItemProcedimiento = () => {
    const { id_item, id_contrato, id_subcategoria } = useParams();
    const [listaPP, setListaPP] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState('');

    useEffect(() => {
        retreiveProcedimientoPersona(id_item);
        retreiveItem(id_item);
    }, []);

    async function retreiveProcedimientoPersona(id_item) {
        setLoading(true);
        var lista = [];
        const queryProc = await query(collection(FireBaseApp.db, "procedimientos"), where("id_item", "==", id_item));
        const snapProc = await getDocs(queryProc);
        snapProc.forEach(async (docProc) => {
            const queryProcPer = await query(collection(FireBaseApp.db, "procedimiento_persona"), where("id_procedimiento", "==", docProc.id));
            const snapProcPer = await getDocs(queryProcPer);
            console.log(snapProcPer.empty);
            if(!snapProcPer.empty) {
                snapProcPer.forEach(async (docPP) => {
                    //Get persona
                    const personaRef = await doc(FireBaseApp.db, "personas", docPP.data().id_persona);
                    const personaData = await getDoc(personaRef);
                    //Get Procedimiento
                    const procRef = await doc(FireBaseApp.db, "procedimientos", docPP.data().id_procedimiento);
                    const procData = await getDoc(procRef);
                    lista.push({ nombre_persona: personaData.data().nombre + " " + personaData.data().apellido_paterno, nombre_procedimiento: procData.data().nombre, ...docPP.data() });
                    setListaPP([...lista]);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });
    };

    async function retreiveItem(id_item) {
        const refItem = await doc(FireBaseApp.db, "items", id_item);
        const docItem = await getDoc(refItem);
        setItem({ id: docItem.id, ...docItem.data() });
    }

    const columns = [
        /* {
            accessorKey: 'id_persona',
            header: 'ID PERSONA',
            size: 40
        }, */
        {
            accessorKey: 'nombre_persona',
            header: 'ID PERSONA',
            size: 40
        },
        {
            accessorKey: 'nombre_procedimiento',
            header: 'ID PROCEDIMIENTO',
            size: 40
        },
        {
            accessorKey: 'porcentaje',
            header: '%',
            size: 40
        },
        {
            accessorKey: 'url_archivo_proc',
            header: 'URL',
            size: 40,
            Cell: ({ cell, row }) => {
                console.log(cell.getValue());
                if (cell.getValue()) {
                    return (
                        <IconButton onClick={() => window.open(cell.getValue(), "_blank")}>
                            <PictureAsPdfIcon color="success" />
                        </IconButton>
                    )
                } else {
                    return (
                        <PictureAsPdfIcon color="error" />
                    )
                }
            }
        },
        {
            accessorKey: 'id_procedimiento',
            header: 'Realizado',
            size: 40,
            Cell: ({ cell, row }) => {
                console.log(cell.getValue());
                if (row.original.porcentaje == 100) {
                    return (
                        <CheckIcon color="success" />
                    )
                } else {
                    return (
                        <CloseIcon color="error" />
                    )
                }
            }
        },
    ]

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3,
                    justifyContent: "start",
                    alignItems: "start",
                    display: "flex"
                }}>
                    <Button variant="contained" color="secondary" onClick={() => navigate("/inicio/" + id_contrato + "/" + id_subcategoria)}>Volver</Button>
                </Box>
                <Box sx={{
                        margin: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }}>
                        <Typography variant="h6">
                            {item.numero + " - " + item.nombre}
                        </Typography>
                    </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <MaterialReactTable
                        columns={columns}
                        data={listaPP}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default VerItemProcedimiento;