import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { DotLoaderOverlay } from 'react-spinner-overlay';

/** MUI */
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";

/** Iconos */
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';


/** FireBase */
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

import {
    MaterialReactTable
} from "material-react-table";
import ResponsiveAppBar from "../../components/navbar.component";
import FireBaseApp from "../../firebase/firebase";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${props.value}%`}
                </Typography>
            </Box>
        </Box>
    );
};

function nombreArchivo(arhivo) {
    var nom = arhivo;
    var extension = /[^.]+$/.exec(nom);
    var sp = "." + extension;
    var nom_sin_extension = nom.split(sp);
    return nom_sin_extension;
};

const ItemPoliticas = () => {
    const { id_contrato, id_item } = useParams();
    const [archivo, setArchivo] = useState('');
    const [nombre, setNombre] = useState('');
    const [percent, setPercent] = useState(0);
    const storage = getStorage(FireBaseApp.app);
    const [politicas, setPoliticas] = useState([]);
    const [loading, setLoading] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    useEffect(() => {
        retreivePoliticas(id_item);
    }, []);

    async function retreivePoliticas(id_item) {
        setLoading(true);
        var arr_temp = [];
        const queryPol = await query(collection(FireBaseApp.db, "politicas"), where("item", "==", id_item));
        const snapPol = await getDocs(queryPol);
        if (!snapPol.empty) {
            snapPol.forEach((document) => {
                var data = { id: document.id, ...document.data() };
                arr_temp.push(data);
            });
            setPoliticas([...arr_temp]);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const eliminarPolitica = async (id_pol) => {
        setLoading(true);
        const docRef = await doc(FireBaseApp.db, "politicas", id_pol);
        deleteDoc(docRef);
        retreivePoliticas(id_item);
        setLoading(false);
    }

    const columns = [
        {
            accessorKey: 'nombre',
            header: 'Nombre',
            size: 300
        },
        {
            accessorKey: 'url',
            header: 'Descarga',
            size: 20,
            Cell: ({ cell }) => {
                return (
                    <IconButton onClick={() => window.open(cell.getValue(), "_blank")}>
                        <PictureAsPdfIcon color="success" />
                    </IconButton>
                )
            }
        },
        {
            accessorKey: 'id',
            header: 'Eliminar',
            size: 20,
            Cell: ({ cell }) => {
                return (
                    <IconButton onClick={() => eliminarPolitica(cell.getValue())}>
                        <DeleteIcon color="error" />
                    </IconButton>
                )
            }
        },
    ];

    const guardarPolitica = () => {
        /* if (!archivo) {
            alert("Debe agregar un archivo");
            return;
        }
        if (!nombre) {
            alert("Debe ingresar un nombre al documento");
            return
        } */

        if (!files) { //!archivo || 
            alert("Debe agregar un archivo");
            return;
        }
        for (var f in acceptedFiles) {
            //Subimos el Archivo
            const nombre_file = nombreArchivo(acceptedFiles[f].path);
            const storageRef = ref(storage, `/politicas/${acceptedFiles[f].path}`);
            const uploadTask = uploadBytesResumable(storageRef, acceptedFiles[f]);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.error(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        console.log(url);
                        //Actualizamos el derivado
                        const docRef = await addDoc(collection(FireBaseApp.db, "politicas"), {
                            nombre: nombre_file,
                            url: url,
                            item: id_item
                        });
                        setPercent(0);
                        retreivePoliticas(id_item);
                    });
                }
            );
        }
    };

    const handleArchivo = (valor) => {
        setLoading(true);
        setArchivo(valor.target.files[0]);
        setLoading(false);
    };

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
            ) : ""}
            <ResponsiveAppBar id_contrato={id_contrato} />
            <Container>
                <Box sx={{
                    margin: 3,
                    alignContent: "center",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <Stack direction="column" spacing={2}>
                        <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Arrastre sus archivos aqui, o haga clic para seleccionarlos</p>
                            </div>
                            <aside>
                                <h4>Archivos a Cargar</h4>
                                <ul>{files}</ul>
                            </aside>
                        </section>
                        {/* <TextField
                            label="Nombre Documento"
                            name="nombre"
                            fullWidth
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <IconButton component="label">
                            <CloudUploadIcon fontSize="large" />
                            <input hidden type="file" multiple onChange={(e) => handleArchivo(e)} />
                        </IconButton>
                        {archivo !== '' ? (
                            <p>{archivo.name}</p>
                        ) : ("")} */}
                        <Button endIcon={<SaveIcon />} onClick={guardarPolitica}>Guardar</Button>
                        {percent > 0 ? (
                            <Grid item xs sm={3}>
                                <CircularProgressWithLabel value={percent} />
                            </Grid>
                        ) : ""}
                    </Stack>
                </Box>
                <Box sx={{
                    margin: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <MaterialReactTable
                        data={politicas}
                        columns={columns}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableSorting={false}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default ItemPoliticas;